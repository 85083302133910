import React from "react";
import MatterFooterTest from "./MatterFooterTest";
import "./Footer.scss";
import IconDribbble from "../../assets/images/Socials/Dribbble.svg";
import IconLinkedin from "../../assets/images/Socials/Linkedin.svg";
import IconYoutube from "../../assets/images/Socials/Youtube.svg";
import IconNotion from "../../assets/images/Socials/Notion.svg";
import IconGithub from "../../assets/images/Socials/Github.svg";
//import { ScrollRotate } from "react-scroll-rotate";

export default function Footer() {
  return (
    <footer className="Footer">
      <h2 className="main_title">We can talk about more details.</h2>
      <a class="mailto" href="mailto:atsuboy@gmail.com">
        <span>SEND A MESSAGE</span>
      </a>
      <section className="socials">
        <div className="social dribbble">
          <a href="https://dribbble.com/AT_SUSHI">
            <img src={IconDribbble} />
          </a>
        </div>
        <div className="social linkedin">
          <a href="https://www.linkedin.com/in/atsushiienaka/">
            <img src={IconLinkedin} />
          </a>
        </div>
        <div className="social youtube">
          <a href="https://www.youtube.com/channel/UC35IOv-bGr9KiQ65g-MVYsA">
            <img src={IconYoutube} />
          </a>
        </div>
        <div className="social notion">
          <a href="https://www.notion.so/sushinotes/70263e81d8a54c3a85e0fa046efc78ef?v=42532380f361485186239e64ea68b425">
            <img src={IconNotion} />
          </a>
        </div>
        <div className="social github">
          <a href="https://github.com/at-susie">
            <img src={IconGithub} />
          </a>
        </div>
      </section>
      {/* <MatterFooterTest /> */}
      <section className="copyright">© Atsushi Ienaka</section>
    </footer>
  );
}
