import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ImageArticle.scss";
import Plx from "react-plx";

const ArticleLayout = (props) => {
  const layoutType = props.layoutType;
  if (layoutType === "left_image" || window.innerWidth < 560) {
    return (
      <Row className={"image_article left_image " + props.className} id={props.id}>
        <Col sm={6}>
          <div className={"article_visual"}>{props.children}</div>
        </Col>
        <Col sm={6}>
          <div className={"article_body"}>
            <h2 className={"article_title"}>{props.title}</h2>
            <div className={"article_text"}>{props.text}</div>
            <div className={"article_text"}>{props.linkDescription}<a href={props.linkUrl}>{props.linkName}</a></div>
          </div>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className={"image_article right_image " + props.className} id={props.id}>
        <Col sm={6}>
          <div className={"article_body"}>
            <h2 className={"article_title"}>{props.title}</h2>
            <div className={"article_text"}>{props.text}</div>
            <div className={"article_link"}>{props.linkDescription}<a href={props.linkUrl}>{props.linkName}</a></div>
          </div>
        </Col>
        <Col sm={6}>
          <div className={"article_visual"}>{props.children}</div>
        </Col>
      </Row>
    );
  }
};

class ImageArticle extends React.Component {
  render() {
    return (
      <ArticleLayout
        className={this.props.className}
        id={this.props.id}
        layoutType={this.props.layoutType}
        alt={this.props.alt}
        title={this.props.title}
        text={this.props.text}
        linkDescription={this.props.linkDescription}
        linkName={this.props.linkName}
        linkUrl={this.props.linkUrl}
      >
        {this.props.mediaType === "image" && (
          <div className="plx_wrapper">
            <Plx // For Background
              className={"plx_bg"}
              parallaxData={[
                {
                  start: this.props.start,
                  end: this.props.end,
                  duration: this.props.duration,
                  properties: [
                    {
                      property: this.props.propertyBgA,
                      startValue: this.props.startValueBgA,
                      endValue: this.props.endValueBgA,
                    },
                    {
                      property: this.props.propertyBgB,
                      startValue: this.props.startValueBgB,
                      endValue: this.props.endValueBgB,
                    },
                  ],
                },
              ]}
            >
              <img src={this.props.imageBg} alt={this.props.altBg} />
            </Plx>
            <Plx // For Foreground
              className={"plx_fg"}
              parallaxData={[
                {
                  start: this.props.start,
                  end: this.props.end,
                  duration: this.props.duration,
                  properties: [
                    {
                      property: this.props.propertyFgA,
                      startValue: this.props.startValueFgA,
                      endValue: this.props.endValueFgA,
                    },
                    {
                      property: this.props.propertyFgB,
                      startValue: this.props.startValueFgB,
                      endValue: this.props.endValueFgB,
                    },
                  ],
                },
              ]}
            >
              <img src={this.props.imageFg} alt={this.props.altFg} />
            </Plx>
          </div>
        )}
        {this.props.mediaType === "movie" && (
          <div className="plx_wrapper">
            
            <Plx // For Background
              className={"plx_bg"}
              parallaxData={[
                {
                  start: this.props.start,
                  end: this.props.end,
                  duration: this.props.duration,
                  properties: [
                    {
                      property: this.props.propertyBgA,
                      startValue: this.props.startValueBgA,
                      endValue: this.props.endValueBgA,
                    },
                    {
                      property: this.props.propertyBgB,
                      startValue: this.props.startValueBgB,
                      endValue: this.props.endValueBgB,
                    },
                  ],
                },
              ]}
            >
              <img src={this.props.imageBg} alt={this.props.altBg} />
            </Plx>
            <Plx // For Foreground
              className={"plx_fg"}
              parallaxData={[
                {
                  start: this.props.start,
                  end: this.props.end,
                  duration: this.props.duration,
                  properties: [
                    {
                      property: this.props.propertyFgA,
                      startValue: this.props.startValueFgA,
                      endValue: this.props.endValueFgA,
                    },
                    {
                      property: this.props.propertyFgB,
                      startValue: this.props.startValueFgB,
                      endValue: this.props.endValueFgB,
                    },
                  ],
                },
              ]}
            >
              <video
                width={this.props.width}
                height={this.props.height}
                autoPlay
                loop
                muted
              >
                <source src={this.props.movie} type="video/mp4" />
                <source src={this.props.movie} type="video/webm" />
              </video>
            </Plx>

          </div>
        )}
      </ArticleLayout>
    );
  }
}

export default ImageArticle;
