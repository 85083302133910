import React from "react";
//import Scene from "./MatterHero";
import Scene from "./MatterHero";
import classNames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";

const outerClasses = classNames("hero section center-content");

class Hero extends React.Component {
  render() {
    return (
      <section id={"hero"} className={outerClasses}>
        <div className={"hero_core"}>
          <div className={"hero_desc"}>
            DIGITAL PRODUCT DESIGNER, <br />
            CRAFTING BEAUTIFUL & MEANINGFUL EXPERIENCES <br />
            WITH MY{" "}
            <AnchorLink href="#philosophies" className="link_hero">
              PHILOSOPHY
            </AnchorLink>
            <span>&</span>
            <AnchorLink href="#specialities" className="link_hero">
              SPECIALITIES
            </AnchorLink>
            .
          </div>
          
        </div>
        <ul className={"hero_side hero_left"}>
          <li>ATSUSHI IENAKA</li>
          <li>Tokyo → Stuttgart</li>
        </ul>
        <div className={"hero_side hero_right"}>LAST UPDATE: OCTOBER 2020</div>
        <Scene />
      </section>
    );
  }
}

export default Hero;
