import React, { useEffect, useState, useRef } from "react";
import Matter from "matter-js";
import HiIam from "../../assets/images/Name/HiIam.svg";
import ImageAt from "../../assets/images/Name/letter_at.svg";
import ImageS from "../../assets/images/Name/letter_s.svg";
import ImageU from "../../assets/images/Name/letter_u.svg";
import ImageH from "../../assets/images/Name/letter_h.svg";
import ImageI from "../../assets/images/Name/letter_i.svg";

const STATIC_DENSITY = 15;
const Scene = (props) => {
  const boxRef = useRef(null);
  const canvasRef = useRef(null);
  const [constraints, setContraints] = useState();
  const [scene, setScene] = useState();
  const handleResize = () => {
    setContraints(boxRef.current.getBoundingClientRect());
  };
  useEffect(() => {
    let Engine = Matter.Engine;
    let Render = Matter.Render;
    let World = Matter.World;
    let Bodies = Matter.Bodies;
    let Mouse = Matter.Mouse;
    let MouseConstraint = Matter.MouseConstraint;
    let Constraint = Matter.Constraint;
    let Composites = Matter.Composites;
    let Common = Matter.Common;
    let engine = Engine.create({});
    let render = Render.create({
      element: boxRef.current,
      engine: engine,
      canvas: canvasRef.current,
      options: {
        background: "rgba(255, 0, 0, 0.0)",
        wireframes: false,
      },
    });
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const floor = Bodies.rectangle(0, 0, 0, STATIC_DENSITY, {
      isStatic: true,
      render: {
        fillStyle: "rgba(255, 0, 0, 0.0)",
      },
    });
    const WallLeft = Bodies.rectangle(0, windowHeight / 2, 8, windowHeight, {
      isStatic: true,
      render: {
        fillStyle: "rgba(255, 0, 0, 0.0)",
      },
    });
    const WallRight = Bodies.rectangle(
      windowWidth,
      windowHeight / 2,
      8,
      windowHeight,
      {
        isStatic: true,
        render: {
          fillStyle: "rgba(255, 0, 0, 0.0)",
        },
      }
    );

    // Objects
    const atW = windowWidth < 720 ? 82 : 123;
    const sW = windowWidth < 720 ? 39 : 59;
    const uW = windowWidth < 720 ? 42 : 84;
    const hW = windowWidth < 720 ? 57 : 86;
    const iW = windowWidth < 720 ? 28 : 43;
    const maruR = windowWidth < 720 ? 11 : 16;
    const iamW = windowWidth < 720 ? 106 : 160;

    const atH = windowWidth < 720 ? 60 : 91;
    const sH = windowWidth < 720 ? 44 : 67;
    const uH = windowWidth < 720 ? 44 : 67;
    const hH = windowWidth < 720 ? 65 : 98;
    const iH = windowWidth < 720 ? 46 : 69;
    const iamH = windowWidth < 720 ? 37 : 56;

    const centerPosition = windowWidth / 2;

    const startX =
      windowWidth < 720 ? centerPosition - 110 : centerPosition - 360;
    //const startX = 240;
    const marginLetters = 3;
    const firstSX = startX + atW - 30;
    const uX = firstSX + sW + marginLetters + 8;
    const secondSX = uX + uW + marginLetters - 8;
    const hX = secondSX + sW + marginLetters;
    const iX = hX + hW + marginLetters - 16;

    const AT = Bodies.rectangle(startX, 70, atW, atH, {
      restitution: 0.8,
      render: {
        sprite: {
          texture: ImageAt,
          xScale: windowWidth < 720 ? 0.66 : 1.0,
          yScale: windowWidth < 720 ? 0.66 : 1.0,
        },
      },
    });

    const letterFirstS = Bodies.rectangle(firstSX, 100, sW, sH, {
      restitution: 0.5,
      render: {
        sprite: {
          texture: ImageS,
          xScale: windowWidth < 720 ? 0.66 : 1.0,
          yScale: windowWidth < 720 ? 0.66 : 1.0,
        },
      },
    });

    const letterU = Bodies.rectangle(uX, 90, uW, uH, {
      restitution: 0.6,
      render: {
        sprite: {
          texture: ImageU,
          xScale: windowWidth < 720 ? 0.66 : 1.0,
          yScale: windowWidth < 720 ? 0.66 : 1.0,
        },
      },
    });

    const letterSecondS = Bodies.rectangle(secondSX, 90, sW, sH, {
      restitution: 0.4,
      render: {
        sprite: {
          texture: ImageS,
          xScale: windowWidth < 720 ? 0.66 : 1.0,
          yScale: windowWidth < 720 ? 0.66 : 1.0,
        },
      },
    });

    const letterH = Bodies.rectangle(hX, 100, hW, hH, {
      restitution: 0.4,
      render: {
        sprite: {
          texture: ImageH,
          xScale: windowWidth < 720 ? 0.66 : 1.0,
          yScale: windowWidth < 720 ? 0.66 : 1.0,
        },
      },
    });

    const letterI = Bodies.rectangle(iX, 80, iW, iH, {
      restitution: 0.6,
      render: {
        sprite: {
          texture: ImageI,
          xScale: windowWidth < 720 ? 0.66 : 1.0,
          yScale: windowWidth < 720 ? 0.66 : 1.0,
        },
      },
    });

    const maru = Bodies.circle(iX, 40, maruR, {
      restitution: 1,
      render: {
        fillStyle: "#5ce617", //中身の色
      },
    });
    const HelloIamYposition = windowHeight < 960 ? 200 : 320;
    const HelloIam = Bodies.rectangle(
      windowWidth < 720 ? startX + 40 : startX - 120,
      HelloIamYposition,
      iamW,
      iamH,
      {
        density: 0.04,
        frictionAir: 0.005,
        render: {
          //fillStyle: "#5ce617",
          sprite: {
            texture: HiIam,
            xScale: windowWidth < 720 ? 0.66 : 1.0,
            yScale: windowWidth < 720 ? 0.66 : 1.0,
          },
        },
      }
    );

    World.add(
      engine.world,
      [floor, AT, letterFirstS, letterU, letterSecondS, letterH, letterI],
      { gravity: { x: 0, y: 0.0 } }
    );

    setTimeout(() => {
      World.add(engine.world, [maru], { gravity: { x: 0, y: 0.0 } });
    }, 300);

    setTimeout(() => {
      World.add(engine.world, HelloIam);
      World.add(
        engine.world,
        Constraint.create({
          pointA: { x: startX, y: 0 },
          bodyB: HelloIam,
          pointB: { x: 0, y: -20 },
          render: {
            anchors: false,
            strokeStyle: "#4D4D4D",
            lineWidth: "1",
            type: "line",
          },
        })
      );
    }, 1200);

    setTimeout(() => {
      var stack = Composites.stack(0, -500, 32, 3, 0, 0, function (x, y) {
        var sides = Math.round(Common.random(1, 8));

        // triangles can be a little unstable, so avoid until fixed
        sides = sides === 3 ? 4 : sides;

        // round the edges of some bodies
        var chamfer = null;
        if (sides > 2 && Common.random() > 0.7) {
          chamfer = {
            radius: 10,
          };
        }

        var colors = ["#FFCC66", "#FFFFFF", "#FF8766"];
        var randColor = colors[Math.floor(Math.random() * colors.length)];

        switch (Math.round(Common.random(0, 1))) {
          case 0:
            if (Common.random() < 0.8) {
              return Bodies.rectangle(
                x,
                y,
                windowWidth < 720
                  ? Common.random(26, 40, 53)
                  : Common.random(40, 60, 80),
                windowWidth < 720
                  ? Common.random(26, 40, 53)
                  : Common.random(40, 60, 80),
                {
                  chamfer: chamfer,
                  render: {
                    opacity: Common.random(0.05, 0.15, 0.4),
                    fillStyle: randColor,
                  },
                  frictionAir: 0.08,
                }
              );
            } else {
              return Bodies.rectangle(
                x,
                y,
                windowWidth < 720
                  ? Common.random(53, 80)
                  : Common.random(80, 120),
                windowWidth < 720
                  ? Common.random(66, 106)
                  : Common.random(100, 160),
                {
                  chamfer: chamfer,
                  render: {
                    opacity: Common.random(0.05, 0.15, 0.4),
                    fillStyle: randColor,
                  },
                  frictionAir: 0.15,
                }
              );
            }
          case 1:
            return Bodies.polygon(
              x,
              y,
              sides,
              windowWidth < 720 ? Common.random(32, 50) : Common.random(40, 90),
              {
                chamfer: chamfer,
                render: {
                  opacity: Common.random(0.05, 0.15, 0.4),
                  fillStyle: randColor,
                },
                frictionAir: 0.2,
              }
            );
        }
      });

      World.add(engine.world, stack);
    }, 1700);

    Engine.run(engine);
    Render.run(render);
    setContraints(boxRef.current.getBoundingClientRect());
    setScene(render);
    window.addEventListener("resize", handleResize);

    // add mouse control
    var mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false,
          },
        },
      });

    World.add(engine.world, mouseConstraint);

    // Click to add bodies
    Matter.Events.on(mouseConstraint, "mousedown", function (event) {
      World.add(
        engine.world,
        Bodies.circle(
          Math.round(Math.random() * 2000),
          50,
          Common.random(20, 80, 200, 600),
          {
            restitution: 0.7,
            render: {
              opacity: Common.random(0.1, 0.3, 0.5),
            },
          }
        )
      );
      World.add(
        engine.world,
        Bodies.rectangle(
          Math.round(Math.random() * 2000),
          200,
          Common.random(20, 80, 90, 200),
          Common.random(20, 80, 90, 120),
          {
            chamfer: {
              radius: 8,
            },
            render: {
              opacity: Common.random(0.1, 0.3, 0.5),
            },
          }
        )
      );
    });

  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (constraints) {
      let { width, height } = constraints;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      // Dynamically update canvas and bounds
      scene.bounds.max.x = width;
      scene.bounds.max.y = height;
      scene.options.width = width;
      scene.options.height = height;
      scene.canvas.width = width;
      scene.canvas.height = height;
      // Dynamically update floor
      const floor = scene.engine.world.bodies[0];
      Matter.Body.setPosition(floor, {
        x: width / 2,
        y:
          windowWidth < 720
            ? height + STATIC_DENSITY / 2 - windowHeight / 2 - 40
            : height + STATIC_DENSITY / 2 - 380,
      });
      Matter.Body.setVertices(floor, [
        { x: -40, y: height },
        { x: width + 40, y: height },
        { x: width + 40, y: height + STATIC_DENSITY },
        { x: -40, y: height + STATIC_DENSITY },
      ]);
    }
  }, [scene, constraints]);

  return (
    <div className="MatterArea" id={props.id}>
      <div
        ref={boxRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <canvas ref={canvasRef} />
      </div>
    </div>
  );
};

export default Scene;
