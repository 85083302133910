import React from "react";

class SectionTitle extends React.Component {
  render() {
    return (
      <div className={"section_title"}>
        <div className="main_title_container">
          <h2 className={"main_title"}>{this.props.maintitle}</h2>
        </div>
        <div className={"sub_title"}>{this.props.subtitle}</div>
      </div>
    );
  }
}

export default SectionTitle;
