// MatterTest.js
import React from 'react';
import Matter, { Engine, Events, Runner, World, Bodies } from 'matter-js';

const engine = Engine.create();
const runner = Runner.create();

class MatterFooterTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth, height: 480, bodies: [] };
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    var Engine = Matter.Engine,
      Render = Matter.Render,
      World = Matter.World,
      Bodies = Matter.Bodies,
      Composites = Matter.Composites,
      Mouse = Matter.Mouse,
      MouseConstraint = Matter.MouseConstraint,
      Common = Matter.Common;

    //Wall
    World.add(engine.world, [
      Bodies.rectangle(0, 240, window.innerWidth, 6, {
        isStatic: true,
        render: {
          fillStyle: '#2E2E2E',
          //fillStyle: "#000",
        },
      }),
    ]);

    const xposition = (window.innerWidth / 2) - (window.innerWidth / 2);

    var maru = Bodies.circle(xposition, 40, 16, {
      restitution: 0.5,
      render: {
        fillStyle: "#5ce617", //中身の色
      },
    });

    var shikaku = Bodies.rectangle(xposition, 80, 80, 69, {
      restitution: 0.6,
      render: {
        fillStyle: "#ffffff", //中身の色
      },
    });

    World.add(
      engine.world,
      [maru, shikaku]
    );


    Events.on(engine, 'afterUpdate', this.handleUpdate);
    Runner.run(runner, engine);
  }

  componentWillUnmount() {
    Events.off(engine, 'afterUpdate', this.handleUpdate);
    Runner.stop(runner);
  }

  handleUpdate() {
    const bodies = Matter.Composite.allBodies(engine.world);
    this.setState({ bodies });
  }
  handleButton() {
    const circle = Bodies.circle(0, -1.0, 40);
    Matter.Body.applyForce(circle, { x: 0, y: 0 }, { x: 0, y: -0.2 });
    World.add(engine.world, circle);
  }
  render() {
    const bodyItems = this.state.bodies.map(body => {
      if (!body.parts) return null;
      return body.parts.map((part, idx) => {
        const radius = part.circleRadius;
        const left = part.position.x - radius + this.state.width / 2;
        const top = part.position.y - radius + this.state.height / 2;

        const divStyle = {
          position: 'absolute',
          left,
          top,
          width: radius * 2,
          height: radius * 2,
          backgroundColor: 'lightgreen',
        };
        return <div className={`obj_${idx}`} style={divStyle} key={`obj_${idx}`} >Hey</div>;
      });
    });

    return (
      <React.Fragment>
        <div
          className="object_container"
          style={{
            width: this.state.width,
            height: this.state.height,
            backgroundColor: '#243d52',
          }}
        >
          {bodyItems}
        </div>
        <button className="mattertestbutton" onClick={() => this.handleButton()}>PUSH</button>
      </React.Fragment>
    );
  }
}
export default MatterFooterTest;