import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavHeader from "./components/NavHeader/NavHeader";
import Hero from "./components/Hero/Hero";
import SectionTitle from "./components/SectionTitle";
import ImageCard from "./components/ImageCard";
import ImageHolistic from "./assets/images/card_holistic.png";
import ImageAesthetic from "./assets/images/card_aesthetic.png";
import ImagePrototype from "./assets/images/card_prototype.png";
import ImageArticle from "./components/ImageArticle/ImageArticle";
import ImagePrototypeBg from "./assets/images/spe_prototype_img_bg.png";
import ImageDesignSystemBg from "./assets/images/spe_designsystem_bg.png";
import ImageDesignSystemFg from "./assets/images/spe_designsystem_fg.png";
import DesignStudyFg from "./assets/images/spe_study_fg.png";
import DesignStudyBg from "./assets/images/spe_study_bg.png";
import ImageAutoBg from "./assets/images/auto_img_bg.png";
import Footer from "./components/Footer/Footer";
import ReactGA from "react-ga";
import initGA from "./Analytics";

const movieHifiPrototype =
  "https://at-sushi-portfolio-resources.s3.eu-central-1.amazonaws.com/video/Hikingmap.mp4";
const ImageAutoFg =
  "https://at-sushi-portfolio-resources.s3.eu-central-1.amazonaws.com/video/Forza+driving+simulator.mp4";


function App() {
  const [isTop, setIsTop] = useState(true);
  const [isInPhilosophy, setIsInPhilosophy] = useState(false);
  const [isInSpecialities, setIsInSpecialities] = useState(false);
  const [isInSpeSecond, setIsInSpeSecond] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => { initGA(); }, []);

  useEffect(() => {
    const heroHeight = document.getElementById("hero").clientHeight;
    const philosophyHeight =
      document.getElementById("philosophies").clientHeight;
    const specialityFirstHeight =
      document.getElementById("design_system").clientHeight +
      document.getElementsByClassName("section_title")[1].clientHeight;
    const specialitySecondHeight =
      document.getElementById("prototype").clientHeight +
      document.getElementById("automotive").clientHeight;

    document.addEventListener("scroll", () => {
      const scrollAmount = window.scrollY;

      if (scrollAmount < heroHeight - 200) {
        setIsTop(true);
        setIsInPhilosophy(false);
        setIsInSpecialities(false);
        console.log("In isTop");
      } else if (
        heroHeight - 200 < scrollAmount &&
        scrollAmount < heroHeight + philosophyHeight
      ) {
        setIsTop(false);
        setIsInPhilosophy(true);
        setIsInSpecialities(false);
        console.log("In isInPhilosophy");
      } else if (scrollAmount > heroHeight + philosophyHeight - 200) {
        setIsTop(false);
        setIsInPhilosophy(false);
        setIsInSpecialities(true);
        console.log("In isInSpecialities");
      }
    });
  }, []);

  return (
    <div
      id="wrapper"
      className={
        isInPhilosophy
          ? "show"
          : isInSpecialities
          ? "isInSpecialities show"
          : null
      }
    >
      <NavHeader />
      <Hero />
      <Container>
        <section
          id="philosophies"
          className={isInPhilosophy ? "visibleInView" : "invisibleInView"}
        >
          <SectionTitle
            maintitle="Philosophy"
            subtitle="That are embodied in myself"
          />
          <Row>
            <Col sm>
              <ImageCard
                className="imagecard_1"
                image={ImageHolistic}
                alt="Image for 'HOLISTIC APPROACH'"
                cardtitle="HOLISTIC APPROACH"
                cardtext="Everything starts with users. But I also study holistically - landscape, trends, technologies, etc. to lead the team to a right design direction."
              />
            </Col>
            <Col sm>
              <ImageCard
                className="imagecard_2"
                image={ImageAesthetic}
                alt="Image for 'STRIVE FOR AESTHETIC'"
                cardtitle="STRIVE FOR AESTHETIC"
                cardtext="Lovingly paying full attention to details, not only for how it looks but I design harmonious, scalable and coherent design system."
              />
            </Col>
            <Col sm>
              <ImageCard
                className="imagecard_3"
                image={ImagePrototype}
                alt="Image for 'BELIEVE IN PROTOTYPE'"
                cardtitle="BELIEVE IN PROTOTYPE"
                cardtext="I make design tangible by creating Hi-Fi prototypes and translate all the details to developer’s language."
              />
            </Col>
          </Row>
        </section>

        <section id="specialities">
          <SectionTitle
            maintitle="Specialities"
            subtitle="That I am especially good at"
          />
          <ImageArticle
            id="design_system"
            className="design_system"
            layoutType="left_image"
            mediaType="image"
            imageFg={ImageDesignSystemFg}
            altFg="Image for design system"
            imageBg={ImageDesignSystemBg}
            altBg="Image for design system"
            title="DESIGN SYSTEM"
            text="With deep understanding and lots of experiences in mobile & car UI design works, I've designed design systems for large scale projects for different industries. Creating reusable components both in Sketch app and code and defining comprehensive and coherent design guidelines, I led whole design system introduction processes that achieve qualities, fulfill their esthetic needs and accelerate their design and development process while reducing their design debt."
            start="self"
            duration={560}
            propertyFgA="translateY"
            startValueFgA={30}
            endValueFgA={-50}
            propertyFgB="rotate"
            startValueFgB={7}
            endValueFgB={0}
            propertyBgA="translateY"
            startValueBgA={-70}
            endValueBgA={-10}
            propertyBgB="rotate"
            startValueBgB={7}
            endValueBgB={0}
          />

          <ImageArticle
            id="design_research"
            className="design_research"
            layoutType="right_image"
            mediaType="image"
            imageFg={DesignStudyFg}
            altFg="Image for design research"
            imageBg={DesignStudyBg}
            altBg="Image for design research"
            title="DESIGN RESEARCH"
            text="I'm an analytical designer. Discovering underlying needs from user researches, collecting insights from landscape studies, and making sure the technical feasibilities with engineers, I make all the design elements 'purposeful', and lead the team to a right design direction."
            start="self"
            duration={520}
            propertyFgB="rotate"
            startValueFgB={-10}
            endValueFgB={0}
            propertyBgA="scale"
            startValueBgA={0.5}
            endValueBgA={1.0}
            propertyBgB="rotate"
            startValueBgB={10}
            endValueBgB={0}
          />

          <ImageArticle
            id="prototype"
            className="prototype"
            layoutType="left_image"
            mediaType="movie"
            movie={movieHifiPrototype}
            imageBg={ImagePrototypeBg}
            width={"440"}
            height={"330"}
            title="HI-FI PROTOTYPE"
            text="I make design tangible by building high fidelity prototypes with real data using web technologies including React that allows the team not only to test it and shape the further ideas for improvement, but to translate the every design detail into code that helps us communicate with developers to realize the design for production."
            linkDescription="See "
            linkName="a personal work example."
            linkUrl="https://youtu.be/f4iQfrn2llI"
            start="self"
            duration={500}
            propertyFgA="translateX"
            startValueFgA={20}
            endValueFgA={20}
            propertyFgB="translateY"
            startValueFgB={-20}
            endValueFgB={40}
            propertyBgA="translateY"
            startValueBgA={-10}
            endValueBgA={-60}
          />

          <ImageArticle
            id="automotive"
            className="automotive"
            layoutType="right_image"
            mediaType="movie"
            movie={ImageAutoFg}
            imageBg={ImageAutoBg}
            altBg="Image for automotive products"
            width={"290"}
            height={"163"}
            title="AUTOMOTIVE PRODUCTS"
            text="From concepts to productions and from center display to instrument cluster, I've worked on different types of in-car digital products for world-class automotive companies both in Japan and Germany. Understanding the platforms and unique points in Car-UIs, I've been devoted to designing and prototyping their HMI that reflect their brands and enhance in-car experience."
            start="self"
            duration={480}
            propertyFgB="translateX"
            startValueFgB={50}
            endValueFgB={10}
          />
        </section>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
