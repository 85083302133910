import React from "react";

class ImageCard extends React.Component {
  render() {
    return (
      <div className={"image_card " + this.props.className}>
        <div className={"card_visual"}>
          <img src={this.props.image} alt={this.props.alt} />
        </div>
        <h2 className={"card_title"}>{this.props.cardtitle}</h2>
        <div className={"card_text"}>{this.props.cardtext}</div>
      </div>
    );
  }
}

export default ImageCard;
