import React, { useState, useEffect } from "react";
// import { CSSTransition } from "react-transition-group";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./Header.scss";

const HeaderLogo = (props) => (
  <div className={"header_logo"}>
    <svg width={36} height={32} {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M9.116 5.333c2.448 0 4.343.45 5.687 1.352 1.315.882 1.987 2.255 2.015 4.12v.504l.027-.004.08-.023c.662-.226 3.498-1.822 8.506-4.788h.766v4.266l1.38-.027c.47-.007.947-.013 1.433-.016l1.283-.005v1.626l-.534-.025v.024l-.703-.052c-.962-.036-1.915-.06-2.86-.072v9.468c0 .99.191 1.7.571 2.133.38.432.893.648 1.54.648a2.54 2.54 0 0 0 1.56-.523l.064-.055v.891c-.354.52-.963.984-1.828 1.39-.864.407-1.849.61-2.953.61-.969 0-1.836-.18-2.602-.54a4.026 4.026 0 0 1-1.804-1.67c-.42-.726-.639-1.66-.655-2.802l-.002-9.426h-.539c-.919.047-1.829.107-2.73.18v5.644c0 .636.139 1.097.415 1.383.276.287.612.43 1.008.43.2 0 .373-.02.517-.06l.06-.018v.844c-.353.25-.85.46-1.491.632a8.095 8.095 0 0 1-2.102.258 6.206 6.206 0 0 1-1.945-.304 4.087 4.087 0 0 1-1.57-.915c-.362-.343-.686-.758-.84-1.243-.282.7-.767 1.275-1.582 1.723l-.096.052c-.98.51-2.146.765-3.5.765-1.396 0-2.516-.349-3.36-1.047-.843-.697-1.265-1.609-1.265-2.734 0-.792.221-1.508.664-2.148.424-.614 1.109-1.154 2.055-1.62l.329-.157c.65-.3 2.612-1.12 6.687-1.865l.001-1.476c0-1.406-.32-2.411-.96-3.015-.641-.604-1.576-.906-2.806-.906-.591 0-1.151.08-1.68.24l-.195.063.013.004c.359.116.683.316.972.599.49.479.734 1.068.734 1.765 0 .709-.258 1.308-.773 1.797-.516.49-1.143.735-1.883.735-.73 0-1.349-.232-1.86-.696-.51-.463-.765-1.05-.765-1.757 0-.75.294-1.44.883-2.07.588-.63 1.445-1.136 2.57-1.516 1.125-.38 2.48-.57 4.063-.57zm1.687 7.95c-1.461.362-2.58.872-3.033 1.48-.52.667-.703 1.516-.703 2.227 0 .71.185 1.411.554 1.828.37.416.831.625 1.383.625.469 0 .873-.107 1.211-.32.239-.151.437-.342.588-.573v-5.267z"
          fill="#FFF"
        />
        <path d="M33.5 22.8a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" fill="#5CE617" />
      </g>
    </svg>
  </div>
);

export default function NavHeader() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [menuIsOpened, setMenuIsOpened] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
    setMenuIsOpened(!menuIsOpened);
  };

  return (
    <header className="Header">
      <AnchorLink href="#hero">
        <HeaderLogo />
      </AnchorLink>
      <nav 
        className={menuIsOpened == true ? "Nav opened" : "Nav"}
      >
        <AnchorLink onClick={toggleNav} href="#philosophies">
          PHILOSOPHIES
        </AnchorLink>
        <AnchorLink onClick={toggleNav} href="#specialities">
          SPECIALITIES
        </AnchorLink>
        <a
          onClick={toggleNav}
          href="https://www.notion.so/sushinotes/Resume-ab8f7740d63d4113a32ec410237c379f"
        >
          RÉSUMÉ
        </a>
      </nav>

      <button
        onClick={toggleNav}
        className={menuIsOpened == true ? "Burger open" : "Burger"}
      >
        <div id="nav-icon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
    </header>
  );
}
